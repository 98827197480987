<script setup lang="ts">
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { isEmpty } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useHotelPopularDestination, useIntersectionObserver, ref, useNuxtApp } from '#imports';

import 'swiper/css';
import 'swiper/css/navigation';

const modules = [Navigation];

const { country } = storeToRefs(useConfigStore());
const { locale } = useI18n();
const { getHotelSearchPath, getCityImage, trackPopularDestinationHotel, trackPopularDestinationHotelNav } =
  useHotelPopularDestination();

const isBot = useNuxtApp().$device.isCrawler;

const {
  data: hotels,
  pending,
  execute: fetchHotelPopularDestination,
} = await useAsyncData(
  () =>
    useHotelService().getHotelPopularDestination({
      country: country.value!,
      lang: locale.value,
    }),
  {
    immediate: isBot,
    transform: (data) => data.result.destinations,
  },
);

const hotelRecommendationRef = ref<HTMLElement | null>(null);
const initialState = ref(!isBot);

const { stop } = useIntersectionObserver(
  hotelRecommendationRef,
  // @ts-expect-error
  ([{ isIntersecting }]) => {
    if (isIntersecting && !isBot) {
      fetchHotelPopularDestination();

      initialState.value = false;

      stop();
    }
  },
);
</script>

<template>
  <div ref="hotelRecommendationRef">
    <h2 class="text-extra font-bold mb-20">
      {{ $t('hotel.favoritedestination') }}
    </h2>

    <HotelPopularDestinationPlaceholder v-if="pending || initialState" />

    <div
      v-else-if="!isEmpty(hotels)"
      class="w-full relative"
    >
      <Swiper
        :slides-per-view="5"
        :space-between="20"
        :lazy="!isBot"
        loop
        :navigation="{
          nextEl: '.hotel-popular-button-next',
          prevEl: '.hotel-popular-button-prev',
        }"
        :modules="modules"
        class="swiper w-full rounded"
        @touch-end="trackPopularDestinationHotelNav('slide')"
      >
        <SwiperSlide
          v-for="(hotel, index) in hotels"
          :key="hotel.id"
          class="!w-[204px] mr-20 last:mr-0"
          @click="trackPopularDestinationHotel(hotel)"
        >
          <NuxtLink
            :to="getHotelSearchPath(hotel)"
            external
          >
            <div class="w-full shadow-md hover:cursor-pointer">
              <NuxtImg
                :src="getCityImage(hotel.nameEn)"
                :alt="hotel.name"
                format="webp"
                height="235"
                width="267"
                class="rounded h-full w-full aspect-square object-cover"
                data-testid="hotel-recommended-card"
                :loading="index > 3 ? 'lazy' : 'eager'"
                fit="scale-down"
                sizes="lg:50vw"
                quality="50"
              />
            </div>

            <div
              class="flex flex-col items-start justify-start pt-10"
              data-testid="hotel-recommended-details"
            >
              <p class="font-bold mb-5 text-left text-medium text-gray-darkest">
                {{ hotel.name }}
              </p>

              <p class="text-gray-dark">
                {{ $t('hotel.properties', { value: `${hotel.count}+` }) }}
              </p>
            </div>
          </NuxtLink>
          <div
            v-if="!isBot"
            class="swiper-lazy-preloader"
          ></div>
        </SwiperSlide>
      </Swiper>

      <ApzButton
        class="swiper-button-next hotel-popular-button-next hover:!bg-gray-lightest"
        data-testid="hotel-next-button"
        @click="trackPopularDestinationHotelNav('nav', 'right')"
      />
      <ApzButton
        class="swiper-button-prev hotel-popular-button-prev hover:!bg-gray-lightest"
        data-testid="hotel-prev-button"
        @click="trackPopularDestinationHotelNav('nav', 'left')"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.swiper-button-next,
.swiper-button-prev {
  @apply rounded-full text-gray-darkest bg-white top-[calc(200px/2-15px)] my-auto shadow-md;

  height: 48px;
  width: 48px;

  &::after {
    @apply font-bold;

    font-size: 18px;
  }
}

.swiper-button-prev {
  @apply -left-[24px];
}

.swiper-button-next {
  @apply -right-[24px];
}

.swiper-lazy-preloader {
  @apply border-x-primary border-b-primary;
}
</style>
